<template>
  <div>
    <head-layout  :head-title="headTitle"
                  :head-btn-options="headBtnOptions"
                  @head-save="headSave('save')"
                  @head-save-back="headSave('back')"
                  @head-cancel="headCancel"
                  ></head-layout>
    <div class="newZtFormBox">
      <el-form :inline="true" :model="form" label-width="120px" :rules="rules" ref="ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称:" prop="name">
              <el-input placeholder="名称" class = 'new-form-input' v-model="form.name"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="英文名称:" prop="ename">
              <el-input  placeholder="英文名称" class = 'new-form-input' v-model="form.ename"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="URL:" >
              <el-input  placeholder="Url" class = 'new-form-input' v-model="form.url"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="父级:" >
              <el-tree
                :props="defaultProps"
                ref="tree"
                :data="data"
                :check-strictly="true"
                show-checkbox
                node-key="id"
                default-expand-all
                highlight-current
                @check-change="handleCheckChange">
              </el-tree>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {getDataDirectoryAllByPid, getDataDirectoryById, updateDataDirectory} from "@/api/dataAcquisition/DataAssets";
import {createUdateDataDirectory} from "@/api/dataAcquisition/DataAssets";
export default {
  name: "dataSubjectAdd",
  data(){
    return {
      headTitle:'数据主题新增',
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'change' }
        ],
        ename: [
          { required: true, message: '请输入英文名称', trigger: 'change' }
        ],
      },
      form: {
        id:'',
        pid:'',
        name:'',
        ename:'',
        url:'',
      },
      formLabelWidth: '120px',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  created() {
    this.onLoad();
    if(this.$route.query.id){
      this.getDataDirectoryByIdFn(this.$route.query.id);
      this.headTitle = '编辑数据主题';
    }
  },
  methods:{
    handleCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.form.pid = data.id;
        this.$refs.tree.setCheckedKeys([data.id])
      }
    },
    onLoad() {
      getDataDirectoryAllByPid({}).then(res => {
        console.log(res.data.data,'--------------')
        this.data = res.data.data;
      });
    },
    getDataDirectoryByIdFn(id){
      getDataDirectoryById({id:id}).then((res)=>{
        this.form = res.data.data;
      })
    },
    headSave(type){
      console.log(this.form,'22222')
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.form.id){
            createUdateDataDirectory(this.form).then(()=>{
              this.$message.success('修改成功！');
              if(type=='back'){
                this.headCancel()
              }
            })
          }else{
            createUdateDataDirectory(this.form).then(()=>{
              this.$message.success('新增成功！');
              if(type=='back'){
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },


  }
}
</script>

<style scoped>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
</style>
