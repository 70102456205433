var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "newZtFormBox" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称:", prop: "name" } },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: { placeholder: "名称", autocomplete: "off" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "英文名称:", prop: "ename" } },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: "英文名称",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.ename,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ename", $$v)
                              },
                              expression: "form.ename",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "URL:" } },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: { placeholder: "Url", autocomplete: "off" },
                            model: {
                              value: _vm.form.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "url", $$v)
                              },
                              expression: "form.url",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "父级:" } },
                        [
                          _c("el-tree", {
                            ref: "tree",
                            attrs: {
                              props: _vm.defaultProps,
                              data: _vm.data,
                              "check-strictly": true,
                              "show-checkbox": "",
                              "node-key": "id",
                              "default-expand-all": "",
                              "highlight-current": "",
                            },
                            on: { "check-change": _vm.handleCheckChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }